import { LinkProps } from 'next/link';

import EmbeddedAssets from '../models/Content/EmbeddedAssetsType';
import ParsedEmbeddedAssets from '../models/Content/ParsedEmbeddedAssetsType';
import ContributorType from '../models/Contributors/ContributorType';
import { ImageInstance } from '../models/PrimaryVisuals/PrimaryVisualType';
export { default as getProgramHost } from './getProgramHost';
export { default as hasLength } from './hasLength';
export { default as getRecentPrimaryAudio } from './getRecentPrimaryAudio';
export { default as isFavorite } from './isFavorite';

export function contributorsToSentence(
  contributors:
    | ContributorType[]
    | {
        order: string | number;
        profile: {
          title: string;
        };
      }[]
): string {
  let returnSentence = '';
  const sortedContributors = [...contributors].sort(
    (a, b) => Number(a.order) - Number(b.order)
  );
  switch (sortedContributors.length) {
    case 0:
      return returnSentence;
    case 1:
      returnSentence = sortedContributors[0].profile.title;
      return returnSentence;
    case 2:
      returnSentence = `${sortedContributors[0].profile.title} and ${sortedContributors[1].profile.title}`;
      return returnSentence;
    default:
      for (let i = 0; i < sortedContributors.length; i++) {
        if (i === sortedContributors.length - 2) {
          returnSentence += `${sortedContributors[i].profile.title} and `;
          returnSentence += `${sortedContributors[i + 1].profile.title}`;
        }

        if (i < sortedContributors.length - 2) {
          returnSentence += `${sortedContributors[i].profile.title}, `;
        }
      }
      return returnSentence;
  }
}

export function getItemLink(item: {
  resourceType?: string;
  canonicalSlug?: string;
  canonicalUrl?: string;
  destination?: string;
  page?: number | null;
  slug?: string;
  cmsId?: string;
}): LinkProps {
  switch (item.resourceType) {
    case 'link':
      return item.destination
        ? { href: item.destination }
        : { href: item.canonicalUrl || `/${item.canonicalSlug}` };
    case 'story':
      return {
        href: `/feature/${item.canonicalSlug}`
      };
    case 'event':
      return {
        href: `/events/${item.canonicalSlug}`
      };
    case 'profile':
    case 'episode':
      return {
        href: `/${item.resourceType}/${item.canonicalSlug}`
      };
    case 'collection':
      return item.page
        ? {
            href: `/${item.canonicalSlug}/${item.page}`
          }
        : {
            href: `/${item.canonicalSlug}`
          };
    case 'events':
      return item.page
        ? {
            href: `/events?page=${item.page}`
          }
        : {
            href: `/events`
          };
    case 'song':
      return {
        href: `/song/the-current/${item.cmsId}`
      };
    case 'stream':
      if (item.canonicalSlug === 'carbon-sound-stream')
        return { href: 'https://www.carbonsound.fm/playlist' };
      return {
        href: `/playlist/${item.canonicalSlug}`
      };
    default:
      return {
        href: `/${item.canonicalSlug}`
      };
  }
}

export function parseEmbeddedAssets(
  embeddedAssets: EmbeddedAssets
): ParsedEmbeddedAssets {
  return {
    audio: JSON.parse(embeddedAssets.audio),
    attachments: JSON.parse(embeddedAssets.attachments),
    images: JSON.parse(embeddedAssets.images),
    oembeds: JSON.parse(embeddedAssets.oembeds)
  };
}

function spliceHms(hms: string[]) {
  if (hms[0] == '0' && hms[1] == '0' && hms[2] == ':' && hms[3] == '0') {
    hms.splice(0, 4);
    return hms;
  } else if (hms[0] == '0' && hms[1] == '0' && hms[2] == ':') {
    hms.splice(0, 3);
    return hms;
  } else if (hms[0] == '0') {
    hms.splice(0, 1);
    return hms;
  } else {
    return hms;
  }
}

/**
 * Removes hours from time display if the duration is less than an hour
 * @param hms
 */
export function trimTime(hms: string): string {
  const splitHms = hms.split('');
  const results = spliceHms(splitHms);

  return results !== null ? results.join().replace(/,/g, '') : '';
}

export function cleanAudioSource(
  source: string | undefined | null
): string | undefined {
  return source?.replace(/\/unreplaced_ua|\/%user_agent/i, '/web');
}

export function findImage(
  images: ImageInstance[],
  width: number
): ImageInstance | undefined {
  if (images.length === 0) {
    return undefined;
  }
  const reducer = (a: ImageInstance, b: ImageInstance) => {
    return Math.abs(a.width - width) < Math.abs(b.width - width) ? a : b;
  };

  return images.reduce(reducer);
}
