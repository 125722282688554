import React, { FC } from 'react';
import Link from 'next/link';

interface Props {
  id?: string;
  href: string | object;
  prefetch?: boolean | null | undefined;
  className?: string;
  children: React.ReactNode;
}

const LinkNoPrefetch: FC<Props> = ({
  id = '',
  href,
  className,
  prefetch = false,
  children = ''
}) => {
  return (
    <Link id={id} href={href} className={className} prefetch={prefetch}>
      {children}
    </Link>
  );
};

export default LinkNoPrefetch;

// import LinkNoPrefetch from 'components/LinkNoPrefetch/LinkNoPrefetch';
