import { FC } from 'react';

const styContainer = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
};

const stySeperator = {
  lineHeight: '1',
  marginLeft: '10px'
};

interface Props {
  color?: string;
  containerClassName?: string;
  height?: string;
  elementClass?: string;
  label?: string;
  labelClassName?: string;
  width?: string;
  children?: React.ReactNode;
}

const IconPause: FC<Props> = ({
  children,
  color = 'var(--color-white)',
  containerClassName = '',
  elementClass = '',
  height = '18.5',
  label = '',
  labelClassName = '',
  width = '15.2'
}) => {
  const styLabelSeperator = labelClassName ? {} : stySeperator;
  const styleContainer = containerClassName ? {} : styContainer;

  return (
    <div style={styleContainer} className={containerClassName}>
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 15.2 18.5"
        width={width}
        height={height}
        className={elementClass}
        aria-hidden={label ? true : false}
        aria-label="Pause"
      >
        <path
          fill="none"
          stroke={color}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.7,16V2.6 M12.5,16V2.6"
        />
      </svg>

      {label && (
        <span style={styLabelSeperator} className={labelClassName}>
          {label}
        </span>
      )}

      {children && children}
    </div>
  );
};

export default IconPause;
