import cleanAudioSource from './cleanAudioSource';
import type AudioType from 'models/Audio/AudioType';

function findAudioSource(
  audio?: AudioType | null,
  showAds = true,
  downloadable?: boolean
): string | undefined {
  if (
    !audio ||
    !Array.isArray(audio.encodings) ||
    !audio.encodings.length ||
    (downloadable && !audio.downloadable)
  ) {
    return undefined;
  }

  const encodings = audio.encodings;
  let path;

  encodings.forEach((encoding) => {
    encoding?.locations?.forEach((location) => {
      if (location.location === 'megaphone') {
        path = location.path;
      }
    });
  });

  if (!path) {
    const selectedEncoding =
      encodings.find((encoding) => encoding.httpFilePath?.match(/\.aac$/)) ||
      encodings[0];

    path = selectedEncoding.httpFilePath;
  }

  return cleanAudioSource(showAds ? path : `${path}?dlt=ycdd`);
}

export default findAudioSource;
