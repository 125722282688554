import { FC } from 'react';

const styContainer = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
};

const stySeperator = {
  lineHeight: '1',
  marginLeft: '10px'
};

interface Props {
  borderColor?: string;
  color?: string;
  containerClassName?: string;
  elementClass?: string;
  height?: string;
  label?: string;
  labelClassName?: string;
  width?: string;
  children?: React.ReactNode;
}

const IconPlay: FC<Props> = ({
  borderColor = 'var(--color-current-red)',
  children,
  color = 'var(--color-white)',
  containerClassName = '',
  elementClass = '',
  height = '14.6',
  label = '',
  labelClassName = '',
  width = '12'
}) => {
  const styLabelSeperator = labelClassName ? {} : stySeperator;
  const styleContainer = containerClassName ? {} : styContainer;

  return (
    <div style={styleContainer} className={containerClassName}>
      <svg
        className={elementClass}
        width={width}
        height={height}
        viewBox="0 0 12 14.6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={label ? true : false}
        aria-label="Play"
      >
        <path
          fill={color}
          d="M0,1.2v12.2c0,0.9,1,1.5,1.8,1l9.6-6.2c0.7-0.5,0.7-1.5,0-2L1.8,0.2C1-0.3,0,0.3,0,1.2z"
        />
      </svg>

      {label && (
        <span style={styLabelSeperator} className={labelClassName}>
          {label}
        </span>
      )}

      {children && <span style={stySeperator}>{children}</span>}
    </div>
  );
};

export default IconPlay;
